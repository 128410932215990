import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faMobileAlt, faStar, faGift, faBaby, faSearch } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  return (
    <nav className="navbar sticky-top d-block">
      <div className="navbar-container">
        <div className="navbar-logo hide-in-mobile">
          <a href="/">MyShop</a>
        </div>
        <div className="navbar-search">
          <div className="search-wrapper">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input type="text" placeholder="Search for atta, dal, coke and m..." />
          </div>
        </div>
        <div className="navbar-links">
          <a href="/cart">Cart</a>
          <a href="/profile">Profile</a>
        </div>
      </div>
     
    </nav>
  );
};

export default Navbar;
