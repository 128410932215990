import React from "react";
import FooterMenu from "../Component/FooterMenu";
import product from "../Component/img/product.png";
import ban2 from "../Component/img/ban1.png";
import Navbar from "../Component/Navbar";


function Home() {
  // Array of category data
  const categories = [
    "Vegetables & Fruits",
    "Dairy Products",
    "Beverages",
    "Snacks",
    "Bakery",
    "Meat & Seafood",
    "Frozen Foods",
    "Pantry Staples"
  ];

  const products = [
    { id: 1, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 2, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 3, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 },
    { id: 4, name: "Snacks & Munchies", desc: "Lay's Indian's Magic...", weight: "2 x 90g", price: 40 }
  ];

  return (
<>
<Navbar /> 

    <div className="container-fluid">


    <section className="mt-3">
  <div id="fruitCarousel" class="carousel slide">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row g-3 text-start">
          <div class="col-lg-12 col-12">
            <div class="grocery-banner-1 rounded-4 p-4">
              <h4 class="fw-bold mb-1">Fresh <span class="fw-light text-black">Fruits</span></h4>
              <p>Get Upto <span class="fw-bold">30%</span> Off</p>
              <a href="listing.html" class="btn btn-danger1 btn-sm fw-bold rounded-pill">Order Now <i class="bi bi-arrow-right ms-1"></i></a>
            </div>
          </div>
        </div>
      </div> 

      <div class="carousel-item">
        <div class="row g-3 text-start">
          <div class="col-lg-12 col-12">
            <div class="grocery-banner-1 rounded-4 p-4 ">
              <h4 class="fw-bold mb-1">Fresh <span class="fw-light text-black">Fruits</span></h4>
              <p>Get Upto <span class="fw-bold">30%</span> Off</p>
              <a href="listing.html" class="btn btn-danger1 btn-sm fw-bold rounded-pill">Order Now <i class="bi bi-arrow-right ms-1"></i></a>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#fruitCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#fruitCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</section>


        <div className="row">
            <div className="col-8">
            <h1 className="h11 text-start mt-3">Shop Popular Categories</h1>
            </div>
            <div className="col-4">
            <h1 className="h112 text-end mt-3">View More</h1>
            </div>
        </div>
      
      <div className="row m-0 p-0">
        {categories.map((category, index) => (
          <div className="col-md-3 col-3 m-0 p-0" key={index}>
            <div className="jha">
              <div className="bg-pd">
                <img src={product} className="img-fluid imh1" alt={category} />
              </div>
              <div className="pg-txt mt-1">{category}</div>
            </div>
          </div>
        ))}
      </div>

    


      

<section className="mt-3">
<div class="row g-3 text-start">
<div class="col-lg-6 col-12">
<div class="grocery-banner-1 rounded-4 p-4">
<h4 class="fw-bold mb-1">Fresh <span class="fw-light text-black">Fruits</span></h4>
<p>Get Upto <span class="fw-bold">30%</span> Off</p>
<a href="listing.html" class="btn btn-danger1 btn-sm fw-bold rounded-pill">Order Now <i class="bi bi-arrow-right ms-1"></i></a>
 </div>
</div>

<div class="col-lg-6 col-12">
<div class="grocery-banner-1 rounded-4 p-4">
<h4 class="fw-bold mb-1">Fresh <span class="fw-light text-black">Fruits</span></h4>
<p>Get Upto <span class="fw-bold">30%</span> Off</p>
<a href="listing.html" class="btn btn-danger1 btn-sm fw-bold rounded-pill">Order Now <i class="bi bi-arrow-right ms-1"></i></a>
 </div>
</div>

</div>
</section>

 




      <div className="row">
            <div className="col-8">
            <h1 className="h11 text-start mt-3">Category One</h1>
            </div>
            <div className="col-4">
            <h1 className="h112 text-end mt-3">View More</h1>
            </div>
        </div>

        <div className="row m-0 p-0">
        {categories.map((category, index) => (
          <div className="col-md-3 col-4 m-0 p-0" key={index}>
            <div className="jha">
              <div className="bg-pd">
                <img src={product} className="img-fluid imh1" alt={category} />
              </div>
              <div className="pg-txt mt-1">{category}</div>
            </div>
          </div>
        ))}
      </div>





      <div className="row">
            <div className="col-8">
            <h1 className="h11 text-start mt-3">Category Two</h1>
            </div>
            <div className="col-4">
            <h1 className="h112 text-end mt-3">View More</h1>
            </div>
        </div>

        <div className="row m-0 p-0">
        {categories.map((category, index) => (
          <div className="col-md-3 col-4 m-0 p-0" key={index}>
            <div className="jha">
              <div className="bg-pd">
                <img src={product} className="img-fluid imh1" alt={category} />
              </div>
              <div className="pg-txt mt-1">{category}</div>
            </div>
          </div>
        ))}
      </div>


 

      <div className="row">
            <div className="col-8">
            <h1 className="h11 text-start mt-3">Category Three</h1>
            </div>
            <div className="col-4">
            <h1 className="h112 text-end mt-3">View More</h1>
            </div>
        </div>

        <div className="row m-0 p-0 mb-5 ">
        {categories.map((category, index) => (
          <div className="col-md-3 col-4 m-0 p-0" key={index}>
            <div className="jha">
              <div className="bg-pd">
                <img src={product} className="img-fluid imh1" alt={category} />
              </div>
              <div className="pg-txt mt-1">{category}</div>
            </div>
          </div>
        ))}
      </div>

<div className="mb-5"></div> 

      <FooterMenu />
    </div>
    </>
  );
}

export default Home;