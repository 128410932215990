import React from 'react';
import { Link } from 'react-router-dom';  
import './common.css';  

const FooterMenu = ({ products }) => {
  return (
    <div className="footer footer1 bg-white shadow mt-auto border-top hide-on-desktop">
      <div className="d-flex align-items-center justify-content-between py-1">
        <Link to="/" className="link-dark text-center col   p-1">
          <i className="bi bi-house h3 m-0"></i>
          <p className="small m-0 pt-1 ft9px">Home</p>
        </Link>
        <Link to="/shop" className="text-muted text-center col   p-1">
          <i className="bi bi-shop h3 m-0"></i>
          <p className="small m-0 pt-1 ft9px">Shop</p>
        </Link>
        <Link to="/store-list" className="text-muted text-center col   p-1">
          <i className="bi bi-heart h3 m-0"></i>
          <p className="small m-0 pt-1 ft9px">Wishlist</p>
        </Link>
        <Link to="/cart" className="text-muted text-center col   p-1">
          <i className="bi bi-basket h3 m-0"></i>
          <p className="small m-0 pt-1 ft9px">Cart</p>
        </Link>
        <Link to="/address" className="text-muted text-center col   p-1">
          <i className="bi bi-person h3 m-0"></i>
          <p className="small m-0 pt-1 ft9px">Profile</p>
        </Link>
      </div>
    </div>
  );
};

export default FooterMenu;