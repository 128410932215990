import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import './App.css';
import './Component/common.css'
import './Component/style2.css'
import Navbar from './Component/Navbar';
import Shop from './Pages/Shop';
import Address from './Pages/Address';

function App() {
  return (
    <Router>
      <div className="App">
      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/address" element={<Address />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
